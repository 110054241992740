import React, { Component } from 'react';

class Forecast extends Component {
    state = {
        items: [
            {
            "when": "1 AM",
            "temp": 54.0,
            "phrase": "54 \ud83d\ude0e ",
            "color": "#0009ff"
        }
    ]
    }

    getCookie(cookieName:string) {
        let name = cookieName + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    componentDidMount() {
        console.log("Mounted")
        
        fetch('https://api.easleyowl.com/api/weather/forecast/339870', {
            headers: {
                "Authorization": `Bearer ${this.getCookie('CF_Authorization')}`
            }
        })
        .then(res => res.json())
        .then((data) => {
            this.setState({ items: data.data.forecast })
    })
    .catch((rejection) => {
        console.error("FetchError", rejection);
    })
    }

    render() {
        return (
    <div className="rss-list-container">
        <table border={1}>
            <thead>
                <tr>
                    <th>When</th>
                    <th>Feels Like</th>
                    <th>Forecast</th>
                </tr>
            </thead>
            {Object.entries(this.state.items).map(([key, value]) => (
                <tr key={key}>
                    <td>{value.when}</td>
                    <td>{value.temp}°</td>
                    <td>{value.phrase}</td>

                </tr>
            ))}
        </table>
        <ul>

        </ul>
    </div>
  )
    }

}

export default Forecast




